import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";

const LoginRedirect = (props) => {
  const [text, setText] = useState('Loading...');
  const location = useLocation();
  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    // Successfully logged with the provider
    // Now logging with strapi by using the access_token (given by the provider) in props.location.search
    console.log('logging in strapi now ...');
    //console.log(location.search);
    fetch(`${global.NEXT_PUBLIC_API_URL}/auth/cognito/callback${location.search}`)
      .then(res => {
        if (res.status !== 200) {
          throw new Error(`Couldn't login to Strapi. Status: ${res.status}`);
        }
        return res;
      })
      .then(res => res.json())
      .then(res => {
        // Successfully logged with Strapi
        // Now saving the jwt to use it for future authenticated requests to Strapi
        //console.log(location.search);
        var decodedToken = jwt_decode(location.search);
        console.log(decodedToken.sub);
        //localStorage.setItem('sub', decodedToken.sub);

        //remove ?id_search=
        const tmp = location.search.slice(10,location.search.length);
        var lastIndex = tmp.lastIndexOf('&access_token=');
        const cognitoToken = tmp.slice(0,lastIndex);
        console.log(res);
        localStorage.setItem('cognito', cognitoToken);
        localStorage.setItem('jwt', res.jwt);
        localStorage.setItem('username', res.user.username);
        localStorage.setItem('userid', res.user.id);
        localStorage.setItem('tier', JSON.stringify(res.user.tier));
        setText('You have been successfully logged in. You will be redirected in a few seconds...');
        history.push('/dashboard-page');
        //setTimeout(() => history.push('/'), 2000); // Redirect to homepage after 3 sec
      })
      .catch(err => {
        console.log("error: "+err);
        setText('An error occurred, please see the developer console.')
      });
  }, [history, location.search, params.providerName]);

  return <p>{text}</p>
};

export default LoginRedirect;
