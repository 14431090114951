import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Link from '@material-ui/core/Link';

import styles from "assets/jss/material-kit-pro-react/views/landingPageSections/teamStyle.js";

import seg from "assets/img/tree_c2.png";
import forest from "assets/img/ms_c2.png";
import mnt from "assets/img/mnt_c2.png";

const useStyles = makeStyles(styles);

export default function ExpertiseSection() {
  const classes = useStyles();
  return (
    <div className={classes.sectionGray}>
    <div className={classes.container}>
    <div className={classes.section}>
    <GridContainer justify="center">
      <GridItem xs={12} sm={12} md={8}>
      <h2 className={classes.title}>Our expertise</h2>
      <h5 className={classes.description}>
      With over 10 years of experience in the field of geometric modeling, <br/>
      our team is proficient in the processing of point clouds.<br/>
      Take a look at the list of our publications <Link target="_blank" href="https://julesmorel.com/publications/">here</Link>.
      </h5>
      </GridItem>
    </GridContainer>
    <br/>
    <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <CardHeader image>
                <img src={seg} alt="..."/>
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: `url(${seg})`,
                    opacity: "1"
                  }}
                />
              </CardHeader>
              <CardBody>
              <h4 className={classes.cardTitle}>
                Classification and segmentation
              </h4>
                <p className={classes.description}>
                  Filter and classify LiDAR and photogrammetric point clouds using statistical analysis and built-in Deep Learning models.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <CardHeader image>
                <img src={forest} alt="..."/>
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: `url(${forest})`,
                    opacity: "1"
                  }}
                />
              </CardHeader>
              <CardBody>
              <h4 className={classes.cardTitle}>
                Advanced Analytics
              </h4>
                <p className={classes.description}>
                  Objects detection and counting, canopy segmentation, surface and volume estimation, slope and curvature analysis.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <CardHeader image>
                <img src={mnt} alt="..."/>
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: `url(${mnt})`,
                    opacity: "1"
                  }}
                />
              </CardHeader>
              <CardBody>
              <h4 className={classes.cardTitle}>
                Surface model reconstruction
              </h4>
                <p className={classes.description}>
                  Retrieval of georeferenced ground digital elevation models. Reconstruction of scanned objects as meshed surfaces.
                </p>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
      </div>
      </div>
      </div>
  );
}
