import {
  cardTitle,
  title,
  grayColor,
  container
} from "assets/jss/material-kit-pro-react.js";
import imagesStyle from "assets/jss/material-kit-pro-react/imagesStyles.js";

const teamStyle = {
  container: {
    ...container,
    zIndex: "2",
  },
  section: {
    padding: "50px 0",
    textAlign: "center"
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  sectionGray: {
    background: grayColor[14]
  },
  ...imagesStyle,
  itemGrid: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  itemGridRight: {
    marginLeft: "auto",
  },
  itemGridLeft: {
    marginRight: "auto"
  },
  cardTitle,
  smallTitle: {
    color: grayColor[7]
  },
  description: {
    color: grayColor[0]
  },
  justifyCenter: {
    justifyContent: "center !important"
  },
  socials: {
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
    color: grayColor[0]
  },
  margin5: {
    margin: "5px"
  },
  card3: {
    textAlign: "center"
  },
  toolbarButtonsRight: {
    marginLeft: 'auto',
    paddingRight: "5px",
  },
  toolbarButtonsLeft: {
    marginRight: 'auto',
    paddingLeft: "5px",
  },
};

export default teamStyle;
