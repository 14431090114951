import { container,whiteColor,title, grayColor,lightgreenColor } from "assets/jss/material-kit-pro-react.js";

const productStyle = {
  container: {
    color: whiteColor,
    ...container,
    zIndex: "2"
  },
  sectionGray: {
    background: lightgreenColor
  },
  section: {
    padding: "50px 0",
    textAlign: "center"
  },
  sectionleft: {
    marginTop: "-80px",
    textAlign: "left"
  },
  title: {
    ...title,
    marginBottom: "2rem",
    padding: "20px 0",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  description: {
    color: grayColor[0]
  }
};

export default productStyle;
