/*eslint-disable*/
import React from "react";
import { useState } from "react";
import axios from "axios";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components used to create a google map

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Checkbox from "@material-ui/core/Checkbox";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import BusinessCenter from "@material-ui/icons/BusinessCenter";
import Check from "@material-ui/icons/Check";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinksContact from "components/Header/HeaderLinksContact.js"
import Parallax from "components/Parallax/Parallax.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Reaptcha from 'reaptcha';

import contactUsStyle from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";

const useStyles = makeStyles(contactUsStyle);

export default function ContactUsPage(props) {

  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);
  const [checked, setChecked] = React.useState(false);
  const [data, setData] = useState({ name: "", email: "", message:"" });

  let onVerify = recaptchaResponse => {
    setVerified(true);
  };

  async function postMessage(){
    setLoading(true);
      const dataForm = new FormData();
      dataForm.append('data', JSON.stringify({
           name: `${data.name}`,
           email: `${data.email}`,
           message: `${data.message}`,
         }))

      axios.post( `${global.NEXT_PUBLIC_API_URL}/Messages`,
      dataForm,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    ).then(function(response){
      setLoading(false);
      console.log('Success!');
      props.history.push({pathname: "/"});
      window.location.reload();
    })
    .catch(function(){
      setLoading(false);
      console.log('Fail!');
    });
  }

  const { ...rest } = props;
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Header
        color="transparent"
        links={<HeaderLinksContact /> }
        fixed
        changeColorOnScroll={{
          height: 150,
          color: "white"
        }}
        {...rest}
      />
      <Parallax filter small image={require("assets/img/banner.png")} filter="dark">
      <div className={classes.container}>
        <GridContainer>

        </GridContainer>
      </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.contactContent}>
          <div className={classes.container}>
            <h2 className={classes.title}>Send us a message</h2>
            <GridContainer>
              <GridItem md={12} sm={12}>
                <p>
                  We{"'"}ll get in touch with you as soon as possible.
                  <br />
                  <br />
                </p>
                <form>
                <GridContainer>
                  <GridItem xs={12} sm={6} md={6}>
                    <CustomInput
                      labelText="Your Name"
                      id="first"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: (e) => setData({ ...data, name: e.target.value }),
                        type: "text",
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <CustomInput
                      labelText="Email Address"
                      id="last"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: (e) => setData({ ...data, email: e.target.value }),
                        type: "text",
                      }}
                    />
                  </GridItem>
                </GridContainer>
                  <CustomInput
                    labelText="Your message"
                    id="float"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      multiline: true,
                      rows: 6,
                      onChange: (e) => setData({ ...data, message: e.target.value }),
                      type: "text",
                    }}
                  />
                  <GridContainer
                  direction="row"
                  justify="center"
                  alignItems="center">
                    <GridItem xs={12} sm={6} md={6} >
                      <Reaptcha sitekey="6LcEBt4ZAAAAABucAR8o0LOatC6W91TA6__BOCGw" onVerify={onVerify} />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} >
                      <Button round color="primary"  disabled={!verified} onClick={postMessage}>
                        {loading ? "Sending ... " : "Send Message"}
                      </Button>
                    </GridItem>
                  </GridContainer>
                </form>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer
        content={
          <div>
            <div className={classes.left}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <a href="//wwwjulesmorel.com/" className={classes.block}>
                    Blog
                  </a>
                </ListItem>
              </List>
            </div>
            <div className={classes.right}>
              &copy; {1900 + new Date().getYear()} , PointCloud.studio
            </div>
          </div>
        }
      />
    </div>
  );
}
