import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useState, useEffect } from "react";
// @material-ui/icon

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import HeaderLinksLogged from "components/Header/HeaderLinksLogged.js";
import Parallax from "components/Parallax/Parallax.js";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

//import styles from "assets/jss/material-kit-react/views/landingPage.js";
import styles from "assets/jss/material-kit-pro-react/views/landingPageStyle.js";

// Sections for this page
import MissionSection from "./Sections/MissionSection.js";
import ProductSection from "./Sections/ProductSection.js";
import ExpertiseSection from "./Sections/ExpertiseSection.js";
import Powered from "./Sections/Powered.js";
import TeamSection from "./Sections/TeamSection.js";
import SampleData from "./Sections/SampleData.js";
import Price from "./Sections/Price.js";
import logo from "assets/img/logo2.png";

const useStyles = makeStyles(styles);

export default function LandingPage(props) {

  const [isLogged, setIsLogged] = useState(!!localStorage.getItem('jwt'));

  console.log('username : '+localStorage.getItem('username'));
  console.log('jwt : '+localStorage.getItem('jwt'));
  //s3Manager.getListBuckets();

  useEffect(() => {
    setIsLogged(!!localStorage.getItem('jwt'));
  },[]);

  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRounded,
    classes.imgFluid
  );

  const { ...rest } = props;
  return (
    <div>
      <Header
        color="transparent"
        links={isLogged ? <HeaderLinksLogged /> : <HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 250,
          color: "white"
        }}
        {...rest}
      />
    <Parallax image={require("assets/img/banner.png")} filter="dark">
        <div className={classes.container}>
          <GridContainer direction="column">
            <GridItem xs={12} sm={12} md={6}>
              <img src={logo} alt="..." width="95%" className={imageClasses}/>
            </GridItem>
            <GridItem xs={12} sm={12} md={8}>
              <h4 className={classes.subtitle}>
                Turn your raw point clouds into meaningful information.<br/>
                Process large data-sets and analyze 3D models online.<br/>
                Upload your data and share it with your team.
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>

      <div className={classNames(classes.main, classes.mainRaised)}>
        <div >
          <MissionSection/>
          <ProductSection/>
          <SampleData/>
          <ExpertiseSection/>
          <Price/>
          <TeamSection/>
          <Powered/>
        </div>
      </div>
      <Footer
        content={
          <div>
            <div className={classes.left}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <a href="//www.julesmorel.com/" className={classes.block}>
                    Blog
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href="/terms" target="_self" className={classes.block}>
                    Terms and conditions
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href="/contact-page" target="_self" className={classes.block}>
                    Contact us
                  </a>
                </ListItem>
              </List>
            </div>
            <div className={classes.right}>
              &copy; {1900 + new Date().getYear()} , Point Cloud Studio
            </div>
          </div>
        }
      />
    </div>
  );
}
