import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

//import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import styles from "assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Our mission</h2>
          <h5 className={classes.description}>
            <div>Point Cloud Studio is an online point cloud platform,</div>
            <div>a new service dedicated to host, view, share and analyze 3D data sets</div>
            <div>acquired by LiDAR or photogrammetry. It is simple, fast and secured.</div>
          </h5>
        </GridItem>
      </GridContainer>
    </div>
    </div>
  );
}
