import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CardBody from "components/Card/CardBody.js";

import Grid from '@material-ui/core/Grid';

//import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";
import styles from "assets/jss/material-kit-pro-react/views/landingPageSections/teamStyle.js";

import team1 from "assets/img/id_nb.jpg";

const useStyles = makeStyles(styles);

export default function TeamSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRounded,
    classes.imgFluid
  );
  return (
    <div className={classes.sectionGray}>
    <div className={classes.container}>
    <div className={classes.section}>
      <h2 className={classes.title}>Founder</h2>
      <br />
      <div>
      <GridContainer>
      <Grid container
      direction="row"
      justify="center"
      alignItems="center"
      spacing={3}>
      <Grid item xs={12} sm={12} md={6}>
        <Grid container>
          <GridItem>
            <Grid container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={3}>
              <Grid item xs={4}>
                <div >
                  <img src={team1} alt="..." className={imageClasses} />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: `url(${team1})`,
                        opacity: "1"
                      }}
                    />
                </div>
              </Grid>
              <Grid item xs={6}>
                <h4 className={classes.cardTitle}>
                Jules Morel
                <Button
                justIcon
                simple
                color="linkedin"
                className={classes.margin5}
                href="https://www.linkedin.com/in/jules-morel-73975a39/"
                >
                <i className={classes.socials + " fab fa-linkedin"} />
                </Button>
                </h4>
              </Grid>
            </Grid>
          </GridItem>
          <GridItem>
            <CardBody>
            <p style={{textAlign: 'justify',color:'#555555', margin: 20}}>
             Jules is a computer scientist with 10 years of experience in LiDAR data processing. His research interests include point clouds processing, computer graphics and virtual reality.
             He holds a PhD degree in Computer Sciences from University Aix Marseille (France) and was a postdoc fellow at the University of Tokyo (Japan).</p>
            </CardBody>
          </GridItem>
        </Grid>
      </Grid>
        </Grid>
        </GridContainer>
      </div>
      </div>
    </div>
    </div>
  );
}
