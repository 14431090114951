global.NEXT_PUBLIC_FRONTEND_URL='https://pointcloud.studio'
global.NEXT_PUBLIC_API_URL='https://api.pointcloud.studio'

global.API_GATEWAY='https://w6i7ec9do7.execute-api.eu-west-2.amazonaws.com/production'

global.BUCKET='pointcloudstudiostorage'
global.REGION='eu-west-2'
global.IDENTITY_POOL='eu-west-2:a6cc0eb8-d5e7-49c4-9d04-043ffe9d14d0'
global.USER_POOL='cognito-idp.eu-west-2.amazonaws.com/eu-west-2_lInWcPrvF'

global.stripe_prod0='price_1I2vbfGeSZVgu7kfkVnQxPlr'
global.stripe_prod1='price_1I2vbfGeSZVgu7kfkVnQxPlr'
global.stripe_prod2='price_1I2vbfGeSZVgu7kfkVnQxPlr'
global.stripe_prod3='price_1I2vbfGeSZVgu7kfkVnQxPlr'
global.stripe_url_success='https://pointcloud.studio/plan'
global.stripe_url_error='https://pointcloud.studio/plan'

global.rebrandly_APIKEY='6a46a39bc1f94e7ea4e62f0beeab418e'
