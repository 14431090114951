import React from 'react';
import { useEffect } from "react";
import { DateTime } from "luxon";

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button.js";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Tooltip from "@material-ui/core/Tooltip";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import ShareIcon from '@material-ui/icons/Share';
import * as AWS from 'aws-sdk';

//import styles from "assets/jss/material-kit-react/views/projectList.js";
//import productslistStyle from "assets/jss/material-kit-pro-react/views/dashboard/projectList.js";
import styles from "assets/jss/material-kit-pro-react/views/dashboard/productListStyle.js";
const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const units: Intl.RelativeTimeFormatUnit[] = [
  'year',
  'month',
  'week',
  'day',
  'hour',
  'minute',
  'second',
];

var path = require("path");

function timeAgo(dateTime) {
  const diff = dateTime.diffNow().shiftTo(...units);
  const unit = units.find((unit) => diff.get(unit) !== 0) || 'second';
  const relativeFormatter = new Intl.RelativeTimeFormat('en', {
    numeric: 'auto',
  });
  return relativeFormatter.format(Math.trunc(diff.as(unit)), unit);
};

const ProductsList = (props) => {
  const { prods } = props;
  const classes = useStyles();

  const [modalShare, setModalShare] = React.useState(false);
  const [modalShareIndex, setModalShareIndex] = React.useState(0);
  const [products, setProducts] = React.useState(prods);
  const [openCopiedSnack, setOpenCopiedSnack] = React.useState(false);

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenCopiedSnack(false);
  };

  const [openCognitoSnack, setOpenCognitoSnack] = React.useState(false);

  const handleCloseSnackCognito = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenCognitoSnack(false);
  };

  function copyLinkToClipBoard(id) {
    let request = require("request");
    let linkRequest = {
      destination: `${products[id].signedURL}`,
      domain: { fullName: "pointcloudstudio.link" }
    }

    let requestHeaders = {
      "Content-Type": "application/json",
      "apikey": `${global.rebrandly_APIKEY}`
    }

    request({
        uri: "https://api.rebrandly.com/v1/links",
        method: "POST",
        body: JSON.stringify(linkRequest),
        headers: requestHeaders
    }, (err, response, body) => {
      let link = JSON.parse(body);
      console.log(`Long URL was ${link.destination}, short URL is ${link.shortUrl}`);
      navigator.clipboard.writeText(`${link.shortUrl}`);
      setOpenCopiedSnack(true);
    });
  }

  useEffect(() => {
    console.log('useEffect');
    let prodstmp = [...prods];
    let cognito = localStorage.getItem('cognito');
    if(prodstmp.length>0){
      try{
      AWS.config.region = global.REGION;
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: global.IDENTITY_POOL,
      Logins: {
              'cognito-idp.eu-west-2.amazonaws.com/eu-west-2_lInWcPrvF': cognito
            }
      });
      AWS.config.credentials.get(function(err) {
          if (err) {
            console.log('err '+err);
            setOpenCognitoSnack(true);
            console.log('cognito id fail');
            setTimeout(function() { //Start the timer
              window.location.href = `${global.NEXT_PUBLIC_API_URL}/connect/cognito`;
            }, 2000)
          };
          var s3 = new AWS.S3({
              apiVersion: '2006-03-01',
              params: {Bucket: global.BUCKET}
          });
          var hasChanged=false;
          console.log("prodstmp "+prodstmp);

          for (let [index, prod] of prodstmp.entries()) {
            if(prod.signedURL==null){
              const key = "points/"+AWS.config.credentials._identityId + "/" + prod.path_cover;
              var params={
                Bucket: global.BUCKET,
                Key: key,
                Expires: 86400,
              };
              //console.log('params '+params);
              var url = s3.getSignedUrl('getObject',params);
              console.log(url);
              prod.filename = path.basename(prod.path_cover);
              prod.signedURL=url;
              prodstmp[index]=prod;
              hasChanged=true;
            }
          }
          if(hasChanged){
            setProducts(prodstmp);
          }
      });
      }catch(e){
              console.log('error', e);
      }
    }
  });

  if (!prods || prods.length === 0) return <div></div>;

  return (
    <div className={classes.blob}>
    <div className={classes.container}>

    <h3 className={classes.cardTitle}>
      Products
    </h3>
    <br/>

    <GridContainer justify="center" alignItems="center">
    {products.map((prod,index) => {
      var datestr_update_prod = "Last updated "+timeAgo(DateTime.fromISO(`${prod.updated_at}`));
      //console.log('url '+prod.signedURL);
          return (
            <GridItem xs={12} sm={12} md={4} key={prod.id}>
            <Card color="gray">
              <CardHeader image>
                <a href="#pablo" onClick={e => e.preventDefault()}>
                  <img src={prod.signedURL} alt={"..."} />
                  <div className={classes.cardTitleAbsolute}>
                    {prod.name}
                  </div>
                </a>
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: `url(${prod.signedURL})`,
                    opacity: "1"
                  }}
                />
              </CardHeader>
              <CardBody>
                <h6 className={classes.descriptionType}>{prod.type}</h6>
                <h5 className={classes.description}>{prod.description}</h5>
                <h6 className={classes.description}>{datestr_update_prod}</h6>
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Tooltip
                  id="tooltip-down"
                  title="Download the file"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                <a href={prod.signedURL} target="_blank" rel="noopener noreferrer" download={prod.filename}>
                  <Button round justIcon color="primary">
                  <CloudDownloadIcon/>
                  </Button>
                </a>
                </Tooltip>
                <Tooltip
                  id="tooltip-down"
                  title="Generate a temporary link to share the file"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                <Button round justIcon color="info" onClick={() => {setModalShare(true);setModalShareIndex(index)}}>
                <ShareIcon/>
                </Button>
                </Tooltip>
              </CardFooter>
            </Card>
            </GridItem>
          );
      })
    })}
    </GridContainer>
    <Dialog
      classes={{
        root: classes.center,
        paper: classes.modal
      }}
      open={modalShare}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setModalShare(false)}
      aria-labelledby="modal-slide-title"
      aria-describedby="modal-slide-description"
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <IconButton
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => setModalShare(false)}
        >
          <Close className={classes.modalClose} />
        </IconButton>
        <h4 className={classes.modalTitle}>Share this file</h4>
      </DialogTitle>
      <DialogContent
        id="modal-slide-description"
        className={classes.modalBody}
      >
        <p>Anyone with this link can access the file for 24 hours.</p>
      </DialogContent>
      <DialogActions
        className={classes.modalFooter + " " + classes.modalFooterCenter}
      >
        <Button onClick={() => {setModalShare(false);copyLinkToClipBoard(modalShareIndex)}} color="primary">Copy</Button>
        <Button onClick={() => setModalShare(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>
    <Snackbar open={openCopiedSnack} autoHideDuration={2000} onClose={handleCloseSnack}>
      <Alert onClose={handleCloseSnack} severity="success">
        Link copied to clipboard!
      </Alert>
    </Snackbar>
    <Snackbar open={openCognitoSnack} autoHideDuration={2000} onClose={handleCloseSnackCognito}>
      <Alert onClose={handleCloseSnackCognito} severity="warning">
        You have been disconnected. Redirectiong to login page ...
      </Alert>
    </Snackbar>
    </div>
    </div>
  );
};
export default ProductsList;
