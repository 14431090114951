import React from 'react';
import { DateTime } from "luxon";
import ProductsList from './ProductsList.js'
import Storage from './Storage.js'
import { useEffect } from "react";

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button.js";

import Tooltip from "@material-ui/core/Tooltip";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import ShareIcon from '@material-ui/icons/Share';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

//import styles from "assets/jss/material-kit-react/views/projectList.js";
import projectlistStyle from "assets/jss/material-kit-pro-react/views/dashboard/projectList.js";
//import styles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.js";
const useStyles = makeStyles(projectlistStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AWS = require('aws-sdk/global');

function deleteProjectById(id) {
  console.log('deleting: '+id)
  let userjwt = localStorage.getItem('jwt');
  const apiUrl = `${global.NEXT_PUBLIC_API_URL}/Projects/${id}`;
  fetch(apiUrl, {
    method: "DELETE",
    headers: userjwt && { Authorization: `Bearer ${userjwt}` }})
    .then(function(response){
      window.location.reload();
    }).catch(function(response){
       console.log('Failed to delete project');
    });
}

const units: Intl.RelativeTimeFormatUnit[] = [
  'year',
  'month',
  'week',
  'day',
  'hour',
  'minute',
  'second',
];

function timeAgo(dateTime) {
  const diff = dateTime.diffNow().shiftTo(...units);
  const unit = units.find((unit) => diff.get(unit) !== 0) || 'second';
  const relativeFormatter = new Intl.RelativeTimeFormat('en', {
    numeric: 'auto',
  });
  return relativeFormatter.format(Math.trunc(diff.as(unit)), unit);
};

const ProjectList = (props) => {
  const { projs } = props;
  const classes = useStyles();
  const [projectID, setProjectID] = React.useState(0);

  function handleClick(event) {
      let index = projs.findIndex(x => x.name ===event);
     //console.log(index);
     setProjectID(index);
  }

  const [modal, setModal] = React.useState(false);
  const [modalViewer, setModalViewer] = React.useState(false);
  const [modalShare, setModalShare] = React.useState(false);
  const [openCopiedSnack, setOpenCopiedSnack] = React.useState(false);
  const [isSharable, setIsSharable] = React.useState(true);

  useEffect(() => {
    let tiertmp = localStorage.getItem('tier');
    let tiername=JSON.parse(tiertmp).name;
    if(tiername==='tier0'){
      setIsSharable(false);
      console.log('tier 0');
    }
  }, []);

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenCopiedSnack(false);
  };

  const [openCognitoSnack, setOpenCognitoSnack] = React.useState(false);

  const handleCloseSnackCognito = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenCognitoSnack(false);
  };

  function copyLinkToClipBoard() {
    let cognito = localStorage.getItem('cognito');
    AWS.config.region = global.REGION;

    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: global.IDENTITY_POOL,
    Logins: {
            'cognito-idp.eu-west-2.amazonaws.com/eu-west-2_lInWcPrvF': cognito
          }
    });
    AWS.config.credentials.get(function(err) {
        if (err) {
          setOpenCognitoSnack(true);
          console.log('cognito id fail');
          setTimeout(function() { //Start the timer
            window.location.href = `${global.NEXT_PUBLIC_API_URL}/connect/cognito`;
          }, 2000)
        }
        var s3 = new AWS.S3({
            apiVersion: '2006-03-01',
            params: {Bucket: global.BUCKET}
        });
        const key = decodeURIComponent(projs[projectID].pathPoints);
        console.log('key '+key);
        var params={
          Bucket: global.BUCKET,
          Key: key,
          Expires: 86400,
        };
        var url = s3.getSignedUrl('getObject',params);
        console.log('url '+url);

        let request = require("request");
        let linkRequest = {
          destination: `${url}`,
          domain: { fullName: "pointcloudstudio.link" }
        }

        let requestHeaders = {
          "Content-Type": "application/json",
          "apikey": `${global.rebrandly_APIKEY}`
        }

        request({
            uri: "https://api.rebrandly.com/v1/links",
            method: "POST",
            body: JSON.stringify(linkRequest),
            headers: requestHeaders
        }, (err, response, body) => {
          let link = JSON.parse(body);
          console.log(`Long URL was ${link.destination}, short URL is ${link.shortUrl}`);
          navigator.clipboard.writeText(`${link.shortUrl}`);
          setOpenCopiedSnack(true);
        });
    });
  }

  if (!projs || projs.length === 0) return <div className={classes.section}>
                                           <h2 className={classes.title}>Your Dashboard is empty.</h2>
                                           <h3 className={classes.title}>To start using Point Cloud Studio, create a new project.</h3>
                                           </div>;

  //const searchQuery = projs.filter(query =>query.name.toLowerCase().includes(props.search));
  return (
    <div className={classes.container}>
    <div className={classes.section}>
    <Storage projs={projs}/>
    <GridContainer>
      <GridItem xs={12} sm={2} md={2}>
        <CustomDropdown
          buttonText="Projects"
          buttonProps={{
            color: "primary",
            round: true,
          }}
          dropdownList={projs.map(function(item) {  return item['name'];})}
          onClick={(event) => handleClick(event)}
        />
      </GridItem>
      <GridItem xs={12} sm={5} md={5}>
      </GridItem>
      <GridItem xs={12} sm={5} md={5}>
      <Tooltip
        id="tooltip-left"
        title="Visualize the point cloud"
        placement="bottom"
        classes={{ tooltip: classes.tooltip }}
      >
        <Button disabled={!projs[projectID].isViewable} round justIcon color="primary" onClick={() => {setModalViewer(true)}}>
          <VisibilityIcon className={classes.icons} />
        </Button>
      </Tooltip>
      <Tooltip
        id="tooltip-down"
        title="Generate a temporary link to share the point cloud"
        placement="bottom"
        classes={{ tooltip: classes.tooltip }}
      >
        <Button disabled={!isSharable} round justIcon color="info" onClick={() => {setModalShare(true)}}>
        <ShareIcon className={classes.icons}/>
        </Button>
      </Tooltip>
      <Tooltip
        id="tooltip-down2"
        title="Delete the project"
        placement="bottom"
        classes={{ tooltip: classes.tooltip }}
      >
        <Button disabled={!projs[projectID].isDeletable} round justIcon color="danger" onClick={() => {setModal(true)}}>
          <DeleteOutlineIcon className={classes.icons} />
        </Button>
      </Tooltip>
      </GridItem>
    </GridContainer>
    <h2 className={classes.cardTitle}>
      {projs[projectID].name}
    </h2>
    <h5 className={classes.description}>{projs[projectID].description}</h5>
    <h6 className={classes.description}>Last updated {timeAgo(DateTime.fromISO(`${projs[projectID].updated_at}`))}</h6>

    <ProductsList prods={projs[projectID].products} />

    <Dialog
      classes={{
        root: classes.center,
        paper: classes.modal
      }}
      open={modalViewer}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setModalViewer(false)}
      aria-labelledby="modal-slide-title"
      aria-describedby="modal-slide-description"
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <IconButton
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => setModalViewer(false)}
        >
          <Close className={classes.modalClose} />
        </IconButton>
        <h4 className={classes.modalTitle}>Launch Viewer?</h4>
      </DialogTitle>
      <DialogContent
        id="modal-slide-description"
        className={classes.modalBody}
      >
        <p>In the viewer, press BACK to return to the Dashboard.</p>
      </DialogContent>
      <DialogActions
        className={classes.modalFooter + " " + classes.modalFooterCenter}
      >
        <Button href={`/viewer-page/${encodeURIComponent(projs[projectID].pathS3)}`} onClick={() => {setModalViewer(false);}} color="primary">
          Yes
        </Button>
        <Button onClick={() => setModalViewer(false)}>No</Button>
      </DialogActions>
    </Dialog>
    <Dialog
      classes={{
        root: classes.center,
        paper: classes.modal
      }}
      open={modal}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setModal(false)}
      aria-labelledby="modal-slide-title"
      aria-describedby="modal-slide-description"
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <IconButton
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => setModal(false)}
        >
          <Close className={classes.modalClose} />
        </IconButton>
        <h4 className={classes.modalTitle}>Delete the project?</h4>
      </DialogTitle>
      <DialogContent
        id="modal-slide-description"
        className={classes.modalBody}
      >
        <p>Deleted data cannot be recovered.</p>
      </DialogContent>
      <DialogActions
        className={classes.modalFooter + " " + classes.modalFooterCenter}
      >
        <Button onClick={() => {setModal(false); deleteProjectById(projs[projectID].id)}} color="danger">
          Delete
        </Button>
        <Button onClick={() => setModal(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>
    <Dialog
      classes={{
        root: classes.center,
        paper: classes.modal
      }}
      open={modalShare}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setModalShare(false)}
      aria-labelledby="modal-slide-title"
      aria-describedby="modal-slide-description"
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <IconButton
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => setModalShare(false)}
        >
          <Close className={classes.modalClose} />
        </IconButton>
        <h4 className={classes.modalTitle}>Share this file</h4>
      </DialogTitle>
      <DialogContent
        id="modal-slide-description"
        className={classes.modalBody}
      >
        <p>Anyone with this link can access the file for 24 hours.</p>
      </DialogContent>
      <DialogActions
        className={classes.modalFooter + " " + classes.modalFooterCenter}
      >
        <Button onClick={() => {setModalShare(false);copyLinkToClipBoard()}} color="primary">Copy</Button>
        <Button onClick={() => setModalShare(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>
    <Snackbar open={openCopiedSnack} autoHideDuration={2000} onClose={handleCloseSnack}>
      <Alert onClose={handleCloseSnack} severity="success">
        Link copied to clipboard!
      </Alert>
    </Snackbar>
    <Snackbar open={openCognitoSnack} autoHideDuration={2000} onClose={handleCloseSnackCognito}>
      <Alert onClose={handleCloseSnackCognito} severity="warning">
        You have been disconnected. Redirectiong to login page ...
      </Alert>
    </Snackbar>
    </div>
  </div>
  );
};
export default ProjectList;
