import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import './global.js';

import "./assets/scss/material-kit-pro-react.scss?v=1.9.0";

// pages for this product
import LandingPage from "views/LandingPage/LandingPage.js";
import TermsPage from "views/LandingPage/Terms.js";
import DashboardPage from "views/Dashboard/DashboardPage.js";
import NewProjectPage from "views/Dashboard/NewProjectPage.js";
import UpgradePlanPage from "views/Plan/UpgradePlan.js";
import ViewerPage from "views/Dashboard/ViewerPage.js";
import ViewerPublicPage from "views/Dashboard/ViewerPublicPage.js";
import ContactPage from "views/Contact/ContactPage.js";
import LoginRedirect from 'lib/loginRedirect';
import PlanPage from "views/Dashboard/Plan.js";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/connect/cognito/redirect" component={LoginRedirect} />
      <Route path="/dashboard-page" component={DashboardPage} />
      <Route path="/newproject-page" component={NewProjectPage} />
      <Route path="/upgrade-page" component={UpgradePlanPage} />
      <Route path="/contact-page" component={ContactPage} />
      <Route path="/plan" component={PlanPage} />
      <Route path="/terms" component={TermsPage} />
      <Route path="/viewer-page/:path" component={ViewerPage} />
      <Route path="/viewerpublic-page/:path" component={ViewerPublicPage} />
      <Route path="/" component={LandingPage} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
