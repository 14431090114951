import {
  title,
  mrAuto,
  mlAuto,
  cardTitle,
  whiteColor,
  infoColor,
  container,
  hexToRgb,
  grayColor
} from "assets/jss/material-kit-pro-react.js";

const pricingStyle = {
  mrAuto,
  mlAuto,
  cardTitle,
  container: {
    ...container,
    zIndex: "2",
  },
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor + " !important",
    "& small": {
      color: "rgba(" + hexToRgb(whiteColor) + ",0.8)!important"
    }
  },
  textCenter: {
    textAlign: "center"
  },
  pricingSection: {
    padding: "80px 0px"
  },
  textInfo: {
    color: infoColor[0] + " !important"
  },
  section: {
    padding: "50px 0",
    textAlign: "center"
  },
  title: {
    ...title,
    marginBottom: "1rem",
    padding: "20px 0px",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center"
  },
  description: {
    color: grayColor[0],
    textAlign: "center"
  },
  descriptionleft: {
    color: grayColor[0],
    textAlign: "left"
  },
  icon: {
    color: "rgba(" + hexToRgb(infoColor[0]) + ", 0.76)",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid " + infoColor[0],
    borderRadius: "50%",
    lineHeight: "174px",
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      fontSize: "55px",
      lineHeight: "55px"
    },
    "& svg": {
      width: "55px",
      height: "55px"
    }
  },
  iconWhite: {
    color: infoColor[0]
  },
};

export default pricingStyle;
