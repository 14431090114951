import React from "react";

function iframe(path) {
    return {
        __html: `<iframe src="${global.NEXT_PUBLIC_FRONTEND_URL}/potree_public/viewer.html?${path}"  onload="this.width=screen.width;this.height=screen.height;"></iframe>`
    }
}

const ViewerPage = (props) => {

  const {path} = props.match.params;
  console.log('ViewerPage path:'+path)
    return (<div>
            <div dangerouslySetInnerHTML={iframe(path)} />
            </div>);
}
export default ViewerPage;
