import React from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
//import TouchAppIcon from '@material-ui/icons/TouchApp';

import styles from "assets/jss/material-kit-pro-react/views/landingPageSections/sampleStyle.js";

import hoodpng from "assets/img/hood-min.png";
import wiertzniapng from "assets/img/wiertznia-min.png";
import nancypng from "assets/img/nancy-min.png";
import cameroonpng from "assets/img/cameroon-min.png";

const useStyles = makeStyles(styles);

export default function ExpertiseSection() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
        <h2 className={classes.title}>Sample gallery</h2>
        </GridItem>
      </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={6} md={3}>
            <Card plain>
              <CardHeader image>
                <a href={`/viewerpublic-page/${encodeURIComponent('Hood')}`}>
                  <img src={hoodpng} alt={"..."} />
                  <div className={classes.cardTitleAbsolute}>
                     Washington
                  </div>
                </a>
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: `url(${hoodpng})`,
                    opacity: "1"
                  }}
                />
              </CardHeader>
              <CardBody>
                <h5 className={classes.description}>Aerial LiDAR scan</h5>
                <h6 className={classes.description}>2M points</h6>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card plain>
              <CardHeader image>
                <a href={`/viewerpublic-page/${encodeURIComponent('wiertznia')}`}>
                  <img src={wiertzniapng} alt={"..."} />
                  <div className={classes.cardTitleAbsolute}>
                    Wiertznia
                  </div>
                </a>
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: `url(${wiertzniapng})`,
                    opacity: "1"
                  }}
                />
              </CardHeader>
              <CardBody>
                <h5 className={classes.description}>Photogrammetric point cloud</h5>
                <h6 className={classes.description}>25M points</h6>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card plain>
              <CardHeader image>
                <a href={`/viewerpublic-page/${encodeURIComponent('Nancy')}`}>
                  <img src={nancypng} alt={"..."} />
                  <div className={classes.cardTitleAbsolute}>
                    Nancy
                  </div>
                </a>
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: `url(${nancypng})`,
                    opacity: "1"
                  }}
                />
              </CardHeader>
              <CardBody>
                <h5 className={classes.description}>Terrestrial LiDAR scan</h5>
                <h6 className={classes.description}>6M points</h6>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card plain>
              <CardHeader image>
                <a href={`/viewerpublic-page/${encodeURIComponent('Cameroon')}`}>
                  <img src={cameroonpng} alt={"..."} />
                  <div className={classes.cardTitleAbsolute}>
                    Cameroon
                  </div>
                </a>
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: `url(${cameroonpng})`,
                    opacity: "1"
                  }}
                />
              </CardHeader>
              <CardBody>
                <h5 className={classes.description}>Terrestrial LiDAR scan</h5>
                <h6 className={classes.description}>2M points</h6>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
      </div>

  );
}
