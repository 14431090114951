import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useEffect } from "react";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";

import Weekend from "@material-ui/icons/Weekend";
import Home from "@material-ui/icons/Home";
import AccountBalance from "@material-ui/icons/AccountBalance";
import LocationCityIcon from '@material-ui/icons/LocationCity';

//import Button from "components/CustomButtons/Button.js";
import HeaderLeftLinksNewProject from "components/Header/HeaderLeftLinksNewProject.js";
//import HeaderLeftLinksDashboard from "components/Header/HeaderLeftLinksDashboard.js";
import Parallax from "components/Parallax/Parallax.js";

import { loadStripe } from '@stripe/stripe-js';


//import styles from "assets/jss/material-kit-react/views/dashboardPage.js";
import styles from "assets/jss/material-kit-pro-react/views/pricingSections/planStyle.js";

const stripePromise = loadStripe('pk_test_51HjOf6GeSZVgu7kfrVlZN2gSL8ZXybQfMDHn7S0yKhk7Px9UH9fUJ8wyCsRotUfk4uhdlaUluc8zvhPfHSK9k5wp00FUDYsM42');

const useStyles = makeStyles(styles);

export default function DashboardPage(props) {

  const [tier, setTier] = React.useState(0);
  //const [newTier, setNewTier] = React.useState(0);
  //const [modal, setModal] = React.useState(false);
  //const [loadingChange, setLoadingChange] = React.useState(false);

  useEffect(() => {
    let tiertmp = localStorage.getItem('tier');
    let tiername=JSON.parse(tiertmp).name;
    if(tiername==='tier1'){
      setTier(1);
      console.log('tier 1');
    }
    if(tiername==='tier2'){
      setTier(2);
      console.log('tier 2');
    }
  }, []);

  // function changePlan(newTier) {
  //   setNewTier(newTier);
  //   setModal(true);
  // }

  const handleClick0 = async (event) => {
    // When the customer clicks on the button, redirect them to Checkout.
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        // Replace with the ID of your price
        {price: `${global.stripe_prod0}`, quantity: 1}
      ],
      mode: 'subscription',
      successUrl: `${global.stripe_url_success}`,
      cancelUrl: `${global.stripe_url_error}`,
    });
    console.log(error.message);
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `error.message`.
  };

  const handleClick1 = async (event) => {
    // When the customer clicks on the button, redirect them to Checkout.
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        // Replace with the ID of your price
        {price: `${global.stripe_prod1}`, quantity: 1}
      ],
      mode: 'subscription',
      successUrl: `${global.stripe_url_success}`,
      cancelUrl: `${global.stripe_url_error}`,
    });
    console.log(error.message);
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `error.message`.
  };

  const handleClick2 = async (event) => {
    // When the customer clicks on the button, redirect them to Checkout.
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        // Replace with the ID of your price
        {price: `${global.stripe_prod2}`, quantity: 1}
      ],
      mode: 'subscription',
      successUrl: `${global.stripe_url_success}`,
      cancelUrl: `${global.stripe_url_error}`,
    });
    console.log(error.message);
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `error.message`.
  };

  const handleClick3 = async (event) => {
    // When the customer clicks on the button, redirect them to Checkout.
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        // Replace with the ID of your price
        {price: `${global.stripe_prod3}`, quantity: 1}
      ],
      mode: 'subscription',
      successUrl: `${global.stripe_url_success}`,
      cancelUrl: `${global.stripe_url_error}`,
    });
    console.log(error.message);
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `error.message`.
  };

  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="transparent"
        links={<HeaderLeftLinksNewProject /> }
        fixed
        changeColorOnScroll={{
          height: 150,
          color: "white",
        }}
        {...rest}
      />
      <Parallax small image={require("assets/img/banner.png")} filter="dark">
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <h1 className={classes.title}>Subscription</h1>
          </GridItem>
        </GridContainer>
      </div>
      </Parallax>

      <div className={classNames(classes.main, classes.mainRaised)}>
      <div className={classes.container}>
        <div className={classes.section}>
        <h2 className={classes.cardTitle}>
          Select the plan that best fits your needs
        </h2>
        <h5 className={classes.description}>
          It may take a few hours before your new subscription plan is reflected into your account
        </h5>
        <GridContainer>
          <GridItem xs={12} sm={6} md={3}>
              {(tier===0) ? (
                <Card raised pricing color="info">
                  <CardBody pricing>
                    <h4 className={classes.cardTitleWhite}>
                      Starter
                    </h4>
                    <div className={classes.icon_w}>
                      <Weekend className={classes.iconWhite_w} />
                    </div>
                    <h3 className={classes.cardTitleWhite}>
                      Free
                    </h3>
                    <ul>
                      <li>
                        <b>1</b> projects
                      </li>
                      <li>
                        No shared link
                      </li>
                      <li>
                        <b>200 Mo</b> Disk space
                      </li>
                      <li>
                         Email support
                      </li>
                    </ul>
                  </CardBody>
                </Card>
              ) : (
                <Card plain pricing>
                  <CardBody pricing>
                    <h4 className={classes.cardTitle}>
                      Starter
                    </h4>
                    <div className={classes.icon}>
                      <Weekend className={classes.iconWhite} />
                    </div>
                    <h3 className={classes.cardTitle}>
                      Free
                    </h3>
                    <ul>
                      <li>
                        <b>1</b> projects
                      </li>
                      <li>
                        No shared link
                      </li>
                      <li>
                        <b>200 Mo</b> Disk space
                      </li>
                      <li>
                         Email support
                      </li>
                    </ul>
                    {(tier<0) ? (
                      <Button color="primary" round onClick={handleClick0}>
                        Upgrade
                      </Button>
                    ):(
                      <Button color="info" round onClick={handleClick0}>
                        Downgrade
                      </Button>
                    )}
                  </CardBody>
                </Card>
              )}
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            {(tier===1) ? (
              <Card raised pricing color="info">
                <CardBody pricing>
                <h4 className={classes.cardTitleWhite}>
                  Silver
                </h4>
                <div className={classes.icon_w}>
                  <Home className={classes.iconWhite_w} />
                </div>
                  <h3 className={classes.cardTitleWhite}>
                    29 <small>€ / month</small>
                  </h3>
                  <ul>
                    <li>
                      <b>10</b> projects
                    </li>
                    <li>
                      Unlimited shared links
                    </li>
                    <li>
                      <b>10 Go</b> Disk space
                    </li>
                    <li>
                      Priority email support
                    </li>
                  </ul>
                  <Button color="white" round>
                    Cancel
                  </Button>
                </CardBody>
              </Card>
            ) : (
              <Card plain pricing>
                <CardBody pricing>
                <h4 className={classes.cardTitle}>
                  Silver
                </h4>
                <div className={classes.icon}>
                  <Home className={classes.iconWhite} />
                </div>
                  <h3 className={classes.cardTitle}>
                    29 <small>€ / month</small>
                  </h3>
                  <ul>
                    <li>
                      <b>10</b> projects
                    </li>
                    <li>
                      Unlimited shared links
                    </li>
                    <li>
                      <b>10 Go</b> Disk space
                    </li>
                    <li>
                      Priority email support
                    </li>
                  </ul>
                  {(tier<1) ? (
                    <Button color="primary" round onClick={handleClick1}>
                      Upgrade
                    </Button>
                  ):(
                    <Button color="info" round onClick={handleClick1}>
                      Downgrade
                    </Button>
                  )}
                </CardBody>
              </Card>
            )}
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            {(tier===2) ? (
              <Card raised pricing color="info">
                <CardBody pricing>
                <h4 className={classes.cardTitleWhite}>
                  Gold
                </h4>
                <div className={classes.icon_w}>
                  <LocationCityIcon className={classes.iconWhite_w} />
                </div>
                  <h3 className={classes.cardTitleWhite}>
                    99 <small>€ / month</small>
                  </h3>
                  <ul>
                    <li>
                      <b>50</b> projects
                    </li>
                    <li>
                      Unlimited shared links
                    </li>
                    <li>
                      <b>100 Go</b> Disk space
                    </li>
                    <li>
                      Priority email support
                    </li>
                  </ul>
                  <Button color="white" round>
                    Cancel
                  </Button>
                </CardBody>
              </Card>
            ) : (
              <Card plain pricing>
                <CardBody pricing plain>
                <h4 className={classes.cardTitle}>
                  Gold
                </h4>
                <div className={classes.icon}>
                  <LocationCityIcon className={classes.iconWhite} />
                </div>
                  <h3 className={classes.cardTitle}>
                    99 <small>€ / month</small>
                  </h3>
                  <ul>
                    <li>
                      <b>50</b> projects
                    </li>
                    <li>
                      Unlimited shared links
                    </li>
                    <li>
                      <b>100 Go</b> Disk space
                    </li>
                    <li>
                      Priority email support
                    </li>
                  </ul>
                  {(tier<2) ? (
                    <Button color="primary" round onClick={handleClick2}>
                      Upgrade
                    </Button>
                  ):(
                    <Button color="info" round onClick={handleClick2}>
                      Downgrade
                    </Button>
                  )}
                </CardBody>
              </Card>
            )}
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            {(tier===3) ? (
              <Card raised pricing color="info">
                <CardBody pricing>
                <h4 className={classes.cardTitleWhite}>
                  Entreprise
                </h4>
                  <div className={classes.icon_w}>
                    <AccountBalance className={classes.iconWhite_w} />
                  </div>
                  <h3 className={classes.cardTitleWhite}>
                    299 <small>€ / month</small>
                  </h3>
                  <ul>
                    <li>
                      unlimited projects
                    </li>
                    <li>
                      Unlimited shared links
                    </li>
                    <li>
                      <b>1 To</b> Disk space
                    </li>
                    <li>
                      Phone & email support
                    </li>
                  </ul>
                  <Button color="white" round>
                    Cancel
                  </Button>
                </CardBody>
              </Card>
            ) : (
              <Card plain pricing>
                <CardBody pricing>
                <h4 className={classes.cardTitle}>
                  Entreprise
                </h4>
                  <div className={classes.icon}>
                    <AccountBalance className={classes.iconWhite} />
                  </div>
                  <h3 className={classes.cardTitle}>
                    299 <small>€ / month</small>
                  </h3>
                  <ul>
                    <li>
                      unlimited projects
                    </li>
                    <li>
                      Unlimited shared links
                    </li>
                    <li>
                      <b>1 To</b> Disk space
                    </li>
                    <li>
                      Phone & email support
                    </li>
                  </ul>
                  {(tier<3) ? (
                    <Button color="primary" round onClick={handleClick3}>
                      Upgrade
                    </Button>
                  ):(
                    <Button color="info" round onClick={handleClick3}>
                      Downgrade
                    </Button>
                  )}
                </CardBody>
              </Card>
            )}
          </GridItem>
        </GridContainer>
      </div>
      </div>
      </div>

      <Footer
        content={
          <div>
            <div className={classes.right}>
              &copy; {1900 + new Date().getYear()} , pointcloud.studio
            </div>
          </div>
        }
      />

    </div>
  );
}
