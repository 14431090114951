import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";

import styles from "assets/jss/material-kit-pro-react/views/landingPageSections/poweredStyle.js";

import aws from "assets/img/aws.png";
import potree from "assets/img/potree.png";
import react from "assets/img/react.png";

const useStyles = makeStyles(styles);

export default function Powered() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRounded,
    classes.imgFluid
  );
  return (
    <div className={classes.container}>
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h3 className={classes.title}>Powered by</h3>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
          <Card plain>
            <GridItem xs={6} className={classes.itemGrid}>
              <img src={aws} alt="..." className={imageClasses} />
            </GridItem>
          </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
          <Card plain>
            <GridItem xs={7} className={classes.itemGrid}>
              <img src={potree} alt="..." className={imageClasses} />
            </GridItem>
          </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
          <Card plain>
            <GridItem xs={6} className={classes.itemGrid}>
              <img src={react} alt="..." className={imageClasses} />
            </GridItem>
          </Card>
          </GridItem>
        </GridContainer>
      </div>
      </div>
    </div>
  );
}
