import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import Weekend from "@material-ui/icons/Weekend";
import Home from "@material-ui/icons/Home";
import AccountBalance from "@material-ui/icons/AccountBalance";
import LocationCityIcon from '@material-ui/icons/LocationCity';

//import styles from "assets/jss/material-kit-react/views/landingPageSections/price.js";
import styles from "assets/jss/material-kit-pro-react/views/pricingSections/pricingStyle.js";

const useStyles = makeStyles(styles);

export default function Price() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Storage Pricing</h2>
          <h4 className={classes.description}>Select a plan that fits your needs.<br/>
          All subscription types include the following features: <br/></h4>
          <h5 className={classes.description}>✓ Upload point cloud files in LAS or LAZ format <br/>
          ✓ Access them anytime anywhere around the globe <br/>
          ✓ Annotate, measure distances, areas and volumes <br/>
          </h5>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card plain pricing>
            <CardBody pricing>
              <h4 className={classes.cardTitle}>
                Starter
              </h4>
              <div className={classes.icon}>
                <Weekend className={classes.iconWhite} />
              </div>
              <h3 className={classes.cardTitle}>
                Free
              </h3>
              <ul>
                <li>
                  <b>1</b> projects
                </li>
                <li>
                  No shared link
                </li>
                <li>
                  <b>200 Mo</b> Disk space
                </li>
                <li>
                   Email support
                </li>
              </ul>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card plain pricing>
            <CardBody pricing plain>
            <h4 className={classes.cardTitle}>
              Silver
            </h4>
            <div className={classes.icon}>
              <Home className={classes.iconWhite} />
            </div>
              <h3 className={classes.cardTitle}>
                29 <small>€ / month</small>
              </h3>
              <ul>
                <li>
                  <b>10</b> projects
                </li>
                <li>
                  Unlimited shared links
                </li>
                <li>
                  <b>10 Go</b> Disk space
                </li>
                <li>
                  Priority email support
                </li>
              </ul>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card plain pricing>
            <CardBody pricing plain>
            <h4 className={classes.cardTitle}>
              Gold
            </h4>
            <div className={classes.icon}>
              <LocationCityIcon className={classes.iconWhite} />
            </div>
              <h3 className={classes.cardTitle}>
                99 <small>€ / month</small>
              </h3>
              <ul>
                <li>
                  <b>50</b> projects
                </li>
                <li>
                  Unlimited shared links
                </li>
                <li>
                  <b>100 Go</b> Disk space
                </li>
                <li>
                  Priority email support
                </li>
              </ul>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card plain pricing>
            <CardBody pricing>
            <h4 className={classes.cardTitle}>
              Entreprise
            </h4>
              <div className={classes.icon}>
                <AccountBalance className={classes.iconWhite} />
              </div>
              <h3 className={classes.cardTitle}>
                299 <small>€ / month</small>
              </h3>
              <ul>
                <li>
                  unlimited projects
                </li>
                <li>
                  Unlimited shared links
                </li>
                <li>
                  <b>1 To</b> Disk space
                </li>
                <li>
                  Phone & email support
                </li>
              </ul>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      </div>
    </div>
  );
}
