import React from "react";

function iframe(token,path) {
    return {
        __html: `<iframe src="${global.NEXT_PUBLIC_FRONTEND_URL}/viewer.html?${token}?${path}"  onload="this.width=screen.width;this.height=screen.height;"></iframe>`
    }
}

const ViewerPage = (props) => {

  const {path} = props.match.params;
  let cognito = localStorage.getItem('cognito');

  console.log('props.match.params:'+props.match.params)
  console.log('ViewerPage path:'+path)

    return (<div>
            <div dangerouslySetInnerHTML={iframe(cognito,path)} />
            </div>);

}
export default ViewerPage;
