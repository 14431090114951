import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import TuneIcon from '@material-ui/icons/Tune';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import ShareIcon from '@material-ui/icons/Share';
import PublicIcon from '@material-ui/icons/Public';
import VisibilityIcon from '@material-ui/icons/Visibility';

//import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import styles from "assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.sectionGray}>
    <div className={classes.container}>
    <div className={classes.section}>
        <h2 className={classes.title}>Our services</h2>
        <div className={classes.sectionleft}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={3}>
            <InfoArea
              title="Point cloud hosting"
              description="Host your point cloud data, making it available anywhere and anytime around the globe. "
              icon={PublicIcon}
              iconColor="primary"
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <InfoArea
              title="Point cloud viewer"
              description="Visualize smoothly your data-sets thanks to our built-in viewer based on Potree."
              icon={VisibilityIcon}
              iconColor="primary"
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <InfoArea
              title="Consulting"
              description="If required, our team will process your data and extract useful information using state-of-the-art algorithms."
              icon={TuneIcon}
              iconColor="primary"
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <InfoArea
              title="Data sharing"
              description="Share data and give access to any party who is involved in your project."
              icon={ShareIcon}
              iconColor="primary"
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
    </div>
    </div>
  );
}
