import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons
import DescriptionIcon from '@material-ui/icons/Description';
import FolderIcon from '@material-ui/icons/Folder';
// core components
import Header from "components/Header/Header.js";
import HeaderLeftLinksNewProject from "components/Header/HeaderLeftLinksNewProject.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import {DropzoneArea} from 'material-ui-dropzone';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

//import styles from "assets/jss/material-kit-react/views/loginPage.js";
import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle.js";
import image from "assets/img/banner.png";

const AWS = require('aws-sdk/global');

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(loginPageStyle);

const NewProjectPage = (props) => {
  const [dataProject, setDataProject] = useState({ projectname: "", desc: "", cover:"", dir:"" });
  const [loading, setLoading] = useState(false);
  const [oversizeError, setOversizeError] = useState(false);
  const [progress, setProgress] = useState(0);
  const [maxFileSize, setMaxFileSize] = useState(0);
  const [currentStorageSize, setCurrentStorageSize] = useState(0);
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");

  useEffect(() => {
    let tiertmp = localStorage.getItem('tier');
    let maxSize = JSON.parse(tiertmp).fileSizeMax*1000000;
    setMaxFileSize(maxSize);

    let size = localStorage.getItem('storageSpace');
    setCurrentStorageSize(size);
  }, []);

  const handleCloseOversizeError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOversizeError(false);
  };

function uploadToS3Auth() {

  var filesize = dataProject.cover.size;

  //check if the added file can fit in the current plan storageSpace
  let sizeForecast = filesize+currentStorageSize;
  let tiertmp = localStorage.getItem('tier');
  let maxSize = JSON.parse(tiertmp).storage;
  console.log('sizeForecast '+sizeForecast);
  console.log('maxSize '+maxSize);
  if(sizeForecast>maxSize){
    setOversizeError(true);
  }else{

  setLoading(true);

  let cognito = localStorage.getItem('cognito');
  AWS.config.region = global.REGION;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: global.IDENTITY_POOL,
  Logins: {
					'cognito-idp.eu-west-2.amazonaws.com/eu-west-2_lInWcPrvF': cognito
				}
  });

  AWS.config.credentials.get(function(err) {
      if (err) return console.error(err);

     var file = dataProject.cover;
     var fileName = file.name;
     const projectDir = `uploads/${AWS.config.credentials._identityId}/${dataProject.projectname}`;
     setDataProject({ ...dataProject, dir: `${projectDir}` });
     var fileKey = projectDir + "/" + fileName;
     //console.log('file to upload: '+fileKey);

     //Use S3 ManagedUpload class as it supports multipart uploads
     var upload = new AWS.S3.ManagedUpload({
       params: {
         Bucket: global.BUCKET,
         Key: fileKey,
         Body: file,
       }
     }).on('httpUploadProgress', function(evt) {
    var uploaded = Math.round(evt.loaded / evt.total * 100);
    setProgress(uploaded);
    });

     var promise = upload.promise();

     promise.then(
       function(data) {
           console.log("Successfully file to s3, creating entry in strapi");
           let userjwt = localStorage.getItem('jwt');
           let userid = localStorage.getItem('userid');
           const dataForm = new FormData();

           //console.log('projectDir '+projectDir);
           dataForm.append('data', JSON.stringify({
                name: `${dataProject.projectname}`,
                description: `${dataProject.desc}`,
                user: `${userid}`,
                pathS3: `${projectDir}`,
                pathPoints: `${fileKey}`,
                isViewable:true
              }))

           axios.post( `${global.NEXT_PUBLIC_API_URL}/Projects`,
           dataForm,
           {
             headers: {
               'Authorization': `Bearer ${userjwt}`,
               'Content-Type': 'multipart/form-data'
             }
           }
         ).then(function(response){
           setLoading(false);
           console.log('Success!');
           //setOpenSuccess(true);
           //props.history.push("/dashboard-page");
           props.history.push({
             pathname: "/dashboard-page",
             state: { newproject: true }});
           window.location.reload();
         })
         .catch(function(){
           setLoading(false);
           console.log('Fail!');
           //setOpenError(true);
         });
       },
       function(err) {
         console.log("There was an error uploading the file to S3: ", err.message);
       }
     );
  });
  }
}



  setTimeout(function() {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        absolute
        brand=""
        color="transparent"
        links={<HeaderLeftLinksNewProject />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={5}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <CardHeader color="primary" signup className={classes.cardHeader}>
                    <h4>Create New Project</h4>
                  </CardHeader>
                  <CardBody>
                    <CustomInput
                      labelText="Name..."
                      id="projectname"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: (e) => setDataProject({ ...dataProject, projectname: e.target.value }),
                        type: "text",
                        endAdornment: (
                          <InputAdornment position="end">
                            <FolderIcon className={classes.inputIconsColor} />
                          </InputAdornment>
                        )
                      }}
                    />
                    <CustomInput
                      labelText="Description..."
                      id="pass"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: (e) => setDataProject({ ...dataProject, desc: e.target.value }),
                        type: "text",
                        endAdornment: (
                          <InputAdornment position="end">
                            <DescriptionIcon className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                        autoComplete: "off"
                      }}
                    />
                    <p>As your allowed storage space takes into account the raw files, we advise you to first convert your data-sets into the .laz format then upload it here. Select a point cloud:</p>
                    <DropzoneArea dropzoneText={"Drag and drop a LAS/LAZ file"}
                    showPreviews={true}
                    showPreviewsInDropzone={false}
                    useChipsForPreview
                    previewGridProps={{container: { spacing: 1, direction: 'row' }}}
                    previewChipProps={{classes: { root: classes.previewChip } }}
                    filesLimit={1}
                    maxFileSize={maxFileSize}
                    onChange={(files) => {
                      setDataProject({ ...dataProject, cover: files[0] });
                    }}/>
                    <br/>
                    <div className={classes.textCenter}>
                  {loading &&   <CustomLinearProgress
                                  variant="determinate"
                                  color="primary"
                                  value={progress}
                                />}
                  <Button round color="primary" disabled size="lg" onClick={uploadToS3Auth}>
                  {loading ? "Uploading... " : "Create"}
                  </Button>
                    </div>
                    </CardBody>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Snackbar open={oversizeError} autoHideDuration={5000} onClose={handleCloseOversizeError}>
        <Alert onClose={handleCloseOversizeError} severity="error">
          You reached the limit of your current plan storage. Please consider upgrading
        </Alert>
      </Snackbar>
    </div>
  );
};
export default NewProjectPage;
