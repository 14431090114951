import {
  cardTitle,
  title,
  grayColor,
  container,
  coloredShadow,
  lightgreenColor,
  hexToRgb,
  whiteColor
} from "assets/jss/material-kit-pro-react.js";
import imagesStyle from "assets/jss/material-kit-pro-react/imagesStyles.js";

const teamStyle = {
  coloredShadow,
  container: {
    ...container,
    zIndex: "2",
  },
  sectionGray: {
    background: lightgreenColor
  },
  section: {
    padding: "50px 0",
    textAlign: "center"
  },
  btn: {
    marginTop: "0 !important"
  },
  title: {
    ...title,
    marginBottom: "1rem",
    padding: "20px 0",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  ...imagesStyle,
  itemGrid: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  itemGridRight: {
    marginLeft: "auto",
  },
  itemGridLeft: {
    marginRight: "auto"
  },
  cardTitle,
  smallTitle: {
    color: grayColor[7]
  },
  description: {
    color: grayColor[0]
  },
  justifyCenter: {
    justifyContent: "center !important"
  },
  socials: {
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
    color: grayColor[0]
  },
  margin5: {
    margin: "5px"
  },
  card3: {
    textAlign: "center"
  },
  toolbarButtonsRight: {
    marginLeft: 'auto',
    paddingRight: "5px",
  },
  toolbarButtonsLeft: {
    marginRight: 'auto',
    paddingLeft: "5px",
  },
  cardTitleAbsolute: {
    ...cardTitle,
    position: "absolute !important",
    bottom: "15px !important",
    left: "15px !important",
    color: whiteColor + " !important",
    fontSize: "1.125rem !important",
    textShadow: "0 2px 5px rgba(" + hexToRgb(grayColor[9]) + ", 0.5) !important"
  },
};

export default teamStyle;
