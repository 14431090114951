import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useState, useEffect } from "react";

import List from './ProjectList';
import WithListLoading from './withProjectsLoading';

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

//import Button from "components/CustomButtons/Button.js";
import HeaderLinksDashboard from "components/Header/HeaderLinksDashboard.js";
//import HeaderLeftLinksDashboard from "components/Header/HeaderLeftLinksDashboard.js";
import Parallax from "components/Parallax/Parallax.js";

//import styles from "assets/jss/material-kit-react/views/dashboardPage.js";
import dashboardPageStyle from "assets/jss/material-kit-pro-react/views/dashboardPageStyle.js";

const useStyles = makeStyles(dashboardPageStyle);

export default function DashboardPage(props) {

  const ListLoading = WithListLoading(List);
  const [appState, setAppState] = useState({
    query:"",
    loading: false,
    projs: null,
  });

  useEffect(() => {
    function updateProjects() {
      setAppState({ loading: true });
      let userid = localStorage.getItem('userid');
      let userjwt = localStorage.getItem('jwt');
      const URL = `${global.NEXT_PUBLIC_API_URL}/Projects?user.id=${userid}`;
      fetch(URL, {
        method: "GET",
        headers: { Authorization: `Bearer ${userjwt}` }})
        .then((res) => res.json())
        .then((projs) => {
          setAppState({ loading: false, projs: projs });
          localStorage.setItem('currentNumberProjects', projs.length);
        });
      };
      updateProjects();
  }, []);

  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="transparent"
        links={<HeaderLinksDashboard /> }
        fixed
        changeColorOnScroll={{
          height: 150,
          color: "white",
        }}
        {...rest}
      />
      <Parallax small image={require("assets/img/banner.png")} filter="dark">
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <h1 className={classes.title}>Dashboard </h1>
          </GridItem>
        </GridContainer>
      </div>
      </Parallax>

      <div className={classNames(classes.main, classes.mainRaised)}>
      <div className={classes.container}>
      <ListLoading isLoading={appState.loading} projs={appState.projs} search={appState.query} />
      <br />
      </div>
      </div>

      <Footer
        content={
          <div>
            <div className={classes.right}>
              &copy; {1900 + new Date().getYear()} , PointCloud.studio
            </div>
          </div>
        }
      />

    </div>
  );
}
