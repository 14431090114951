import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import projectlistStyle from "assets/jss/material-kit-pro-react/views/dashboard/projectList.js";
const useStyles = makeStyles(projectlistStyle);

function WithListLoading(Component) {
  const classes = useStyles();
  return function WihLoadingComponent({ isLoading, ...props }) {
    if (!isLoading) return <Component {...props} />;
    return (
      <div className={classes.container}>
      <div className={classes.section}>
        <div>
       <h2 className={classes.title}>Hold on, fetching data ... </h2>
      </div>
      </div>
      </div>
    );
  };
}
export default WithListLoading;
