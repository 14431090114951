import React from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useEffect } from "react";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import Divider from '@material-ui/core/Divider';

import storageStyle from "assets/jss/material-kit-pro-react/views/dashboard/storageStyle.js";
//import styles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.js";

const AWS = require('aws-sdk');
//const s3 = new AWS.S3();

// const listAllKeys = (params, out = []) => new Promise((resolve, reject) => {
//   s3.listObjectsV2(params).promise()
//     .then(({Contents, IsTruncated, NextContinuationToken}) => {
//       out.push(...Contents);
//       !IsTruncated ? resolve(out) : resolve(listAllKeys(Object.assign(params, {ContinuationToken: NextContinuationToken}), out));
//     })
//     .catch(reject);
// });

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props}/>
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles(storageStyle);

export default function Storage(props) {

  const { projs } = props;

  const [name, setName] = React.useState("");
  const [tier, setTier] = React.useState({});
  const [usedSpacePercent, setUsedSpacePercent] = React.useState(0);

  useEffect(() => {

      localStorage.setItem('currentNumberProjects', projs.size);

      let username = localStorage.getItem('username')
      setName(username);
      console.log('name '+username);
      let tiertmp = localStorage.getItem('tier');
      setTier(JSON.parse(tiertmp));
      console.log('tier '+JSON.parse(tiertmp).name);

      let cognito = localStorage.getItem('cognito');
      AWS.config.region = global.REGION;
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: global.IDENTITY_POOL,
      Logins: {
              'cognito-idp.eu-west-2.amazonaws.com/eu-west-2_lInWcPrvF': cognito
            }
      });
      AWS.config.credentials.get(function(err) {
          if (err) {
            console.log('cognito id fail');
            setTimeout(function() { //Start the timer
              window.location.href = `${global.NEXT_PUBLIC_API_URL}/connect/cognito`;
            }, 2000)
          };
          let bucket = new AWS.S3({
                params: {
                    Bucket: global.BUCKET
                }
             });

         const projectDir = "uploads/"+AWS.config.credentials._identityId;
         console.log('projectDir '+projectDir);
         bucket.listObjectsV2({Prefix:`${projectDir}`,Bucket:`${global.BUCKET}`}, function (err, data) {
                if (err) {
                   console.log(err)
                } else {
                   //console.log(data)
                   let size=0;
                   for (let [file] of data.Contents.entries()) {
                     //console.log('file '+file)
                     size+=data.Contents[file].Size;
                   }
                  //console.log('size '+size)
                  let tiertmp = localStorage.getItem('tier');

                  localStorage.setItem('storageSpace', size);

                  let percent = (size/1000000)/JSON.parse(tiertmp).storage*100+1;
                  //console.log('percent '+percent)
                  setUsedSpacePercent(percent);
                }
          });
        });
  }, [projs]);

  const classes = useStyles();
  return (
      <div className={classes.section}>
        <GridContainer direction="row"
                       justify="center"
                       alignItems="center">
          <GridItem xs={12} sm={4} md={4}>
            <GridContainer direction="row"
                         justify="center"
                         alignItems="center">
                <GridItem xs={6} sm={6} md={6}>
                  <h5 className={classes.cardTitle}>Username</h5>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <h5 className={classes.description}>{name}</h5>
                </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <GridContainer direction="row"
                         justify="center"
                         alignItems="center">
                <GridItem xs={6} sm={6} md={6}>
                  <h5 className={classes.cardTitle}>Projects</h5>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <h5 className={classes.description}>{projs.length} / {tier.projectsNumberMax}</h5>
                </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <GridContainer direction="row"
                         justify="center"
                         alignItems="center">
                <GridItem xs={4} sm={4} md={4}>
                  <h5 className={classes.cardTitle}>Storage</h5>
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                  <LinearProgressWithLabel
                    variant="determinate"
                    color="primary"
                    value={usedSpacePercent}
                    classes={{colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary}}
                  />
                </GridItem>
            </GridContainer>

          </GridItem>
        </GridContainer>
        <Divider/>
        </div>
  );
}
